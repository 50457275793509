/* You can add global styles to this file, and also import other style files */

@font-face{
  font-family : 'Material Icons';
  font-style  : normal;
  font-weight : 400;
  src         : url('./assets/angular-material-icons-v17.woff2') format('woff2');
}

.material-icons{
  direction              : ltr;
  display                : inline-block;
  font-family            : 'Material Icons', sans-serif;
  font-size              : 24px;
  -webkit-font-smoothing : antialiased;
  font-style             : normal;
  font-weight            : normal;
  letter-spacing         : normal;
  line-height            : 1;
  text-transform         : none;
  white-space            : nowrap;
  word-wrap              : normal;
}


html, body{
  height : 100%;
}

body{
  font-family : Roboto, 'Helvetica Neue', sans-serif;
  margin      : 0;
}

.required label:after{
  color   : #e32;
  content : ' *';
  display : inline;
}

.helpimageCss{
  height : 19px;
  width  : 19px;
}

.my-custom-tooltip{
  font-size : 20px;
  width     : 1000px !important;
}

.imgDiv{
  float : left;
}

.navTitle{
  color     : white;
  float     : right;
  font-size : 35px;
}

.navbarClass{
  background-color : black !important;
  display          : inline-block !important;
}


html, body{
  height : 100%;
}

body{
  font-family : Roboto, 'Helvetica Neue', sans-serif;
  margin      : 0;
}

.required-field{
  color : #dc3545;
}

.bg-bisque{
  background-color : bisque;
}

.h-80{
  height : 80% !important;
}

.h-60{
  height : 60% !important;
}
